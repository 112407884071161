.LoginPage {
    min-height: inherit;
    width: 400px;
}
.LoginPage_header {
    min-height: 100px;
    height: inherit;
    display: flex;
    justify-content: center;
}
.LoginPage_body {
    display: flex;
    justify-content: center;
}
.LoginPage_form {
    width: 300px;
}

.LoginPage_footer {
    height: 60px;
    font-size: 0.9em;
    padding: 36px;
    /*width: 300px;*/
}

@media (max-width: 576px) {
    .LoginPage_form {
        width: 90%;
    }
}

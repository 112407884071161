.StepContent {
    /* width: 100%; */
}

.StepContent_header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
}
.StepContent_body {
    /*background-color: #eeeeee;*/
    /*background: linear-gradient(*/
    /*    rgba(239, 239, 239, 1),*/
    /*    rgba(239, 239, 239, 0),*/
    /*    rgba(239, 239, 239, 0)*/
    /*);*/
    justify-content: flex-start;
    flex-grow: 1;
    padding-top: 10px;
    max-width: 450px;
    margin: auto;
    padding-bottom: 30px;
}
.StepContent_body > * {
    margin: 20px 27px;
}

@media (max-width: 768px) {
    .StepContent_body > * {
        margin: 0;
    }
}

.PhoneNumberInput_wrapper {
    display: flex;
    gap: 0;
    width: 100%;

}
.PhoneNumberInput_wrapper .Select {
    text-align: left;
}


.PhoneNumberInput_wrapper.PhoneNumberInput_wrapper--vertical {
    flex-direction: column;
    gap: 20px;
}
.PhoneNumberInput_wrapper.PhoneNumberInput_wrapper--horizontal {
    flex-direction: row;
}
.PhoneNumberInput_wrapper.PhoneNumberInput_wrapper--horizontal .Select {
    width: 40%;
}
.PhoneNumberInput_wrapper.PhoneNumberInput_wrapper--horizontal .Input {
    width: 60%;
}
.PhoneNumberInput_wrapper.PhoneNumberInput_wrapper--horizontal .Select .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.PhoneNumberInput_wrapper.PhoneNumberInput_wrapper--horizontal .Input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-left: -1px;
}

/*flip*/
/* entire container, keeps perspective */
.flip-container {
    perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container.hover .flipper {
    transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
    width: 40px;
    height: 40px;
}

/* flip speed goes here */
.flipper {
    transition: 0.8s;
    transform-style: preserve-3d;

    position: relative;
}

/* hide back of pane during swap */
.front,
.back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */
.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
    transform: rotateY(180deg);
}

.TitleText {
    display: block;
    font-size: 1.2em;
    padding: 10px 0;
}

.TitleText--size-large {
    font-size: 1.6em;
    font-weight: 500;
}

.TitleText--size-small {
    font-size: 1.1em;
    font-weight: 400;
    padding: 5px 0;
}

.DocumentScanner-video {
    border-radius: 10px;
}
.DocumentScanner_indicator {
    background: rgba(0, 0, 0, 0.5);
    padding: 2px 8px;
    border-radius: 10px;
    font-weight: 500;
}

.Reticle_container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 96px;
    height: 96px;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    perspective: 600px;
}

.Reticle {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(120, 120, 128, 0.2);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    backdrop-filter: blur(15px);
    transition: all 0.4s ease 0s;
    color: white;
}

.Reticle_cursor {
    font-size: 68px;
    margin: 14px;
    opacity: 0.8;
}

.Reticle_message {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin-top: 5px;
    border-radius: 10px;
    color: white;
    min-width: 300px;
    transform: translate(-50%, 0%);
    transform-origin: center center;
    position: absolute;
    bottom: -50px;
    left: 50%;
}

@media (max-width: 768px) {
    .DocumentScanner-widget-selphid {
        width: 90%;
    }
    .DocumentScanner-video {
        /*min-width: 90% !important;*/
    }
}

@media (max-width: 576px) {
    .DocumentScanner-widget-selphid {
        width: 100%;
    }
}

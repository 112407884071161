.GwFlowFaceCaptureIdrnd {
    height: 40vh;
    /*max-width: 400px;*/
    margin: auto;
    width: 100%;
}
/*.GwFlowFaceCaptureIdrnd-mask {*/
/*    width: 100%;*/
/*    overflow: hidden;*/
/*    height: 100%;*/
/*}*/
.GwFlowFaceCaptureIdrnd-cameraWrapper {
    width: 100%;
    display: inline-block;
    height: 100%;
    position: relative;
}
.GwFlowFaceCaptureIdrnd-cameraWrapper::after {
    overflow: hidden;
}
.GwFlowFaceCaptureIdrnd-loader {
    position: absolute;
    top: 50%;
    left: 50%;
}
.GwFlowFaceCaptureIdrnd-camera {
    width: 100%;
}
.GwFlowFaceCaptureIdrnd-maskWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: transparent;
    visibility: visible;
}
.GwFlowFaceCaptureIdrnd-maskWrapper--loading {
    visibility: hidden;
}
.GwFlowFaceCaptureIdrnd-maskTarget {
    margin: auto -25%;
    position: relative;
    width: 150%;
    height: 100%;
}
.GwFlowFaceCaptureIdrnd-maskTarget::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%; /* Diameter of the circle */
    height: 85%; /* Diameter of the circle */
    background-color: rgba(255, 255, 255, 0); /* Semi-transparent background */
    border-radius: 50%; /* Makes it a circle */
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7); /* Large shadow creates the masking effect */
    filter: blur(0px); /* Adjust the blur effect */
    z-index: 101;
    opacity: 0;
    transition: opacity 0.1s;
}
.GwFlowFaceCaptureIdrnd-maskTarget--opened::after {
    opacity: 1;
}

/* Ensure the content inside the container is visible and not affected by the pseudo-element */
.GwFlowFaceCaptureIdrnd-maskTarget * {
    position: relative;
    z-index: 1;
}

@media (max-width: 768px) {
    .GwFlowFaceCaptureIdrnd {
        width: 100%;
        height: 100vh;
    }
    .GwFlowFaceCaptureIdrnd-maskTarget::after {
        width: 60%; /* Diameter of the circle */
        /*height: 90%; !* Diameter of the circle *!*/
    }
}

@media (max-width: 576px) {
    .GwFlowFaceCaptureIdrnd {
        width: 100%;
        height: 100vh;
    }
    .GwFlowFaceCaptureIdrnd-maskTarget::after {
        width: 60%; /* Diameter of the circle */
        height: 90%; /* Diameter of the circle */
    }
}

@media (max-width: 768px) {
    .GwFlowFaceCapture__scan {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}

@media (max-width: 576px) {
    .GwFlowFaceCapture__scan {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}

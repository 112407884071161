.Select {
}
.Select .ant-select-selector {
    /*padding: 26px 12px !important;*/
    border-radius: 10px !important;
    height: 51px !important;
}
.Select .ant-select-selection-placeholder {
    margin-top: 10px !important;
    font-size: 1.1em;
}

.Select input {
    font-size: 1.1em !important;
    margin-top: 10px !important;
}

.Select .ant-select-selection-item {
    padding-right: 18px;
    margin-top: 8px;
    font-size: 1.1em;
}

.BackButtonWrapper {
    position: relative;
    width: 100%;
}
.BackButtonWrapper-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 102;
    padding: 5px;
    opacity: 0.7;
    display: flex;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
}
.BackButtonWrapper-header-right {
    margin-right: 5px;
}

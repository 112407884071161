.OtpInput {
    gap: 25px !important;
}
.OtpInput .OtpInput_input {
    max-width: 54px;
    text-align: center;
    font-size: 1.5em;
    padding: 16px 12px;
}

@media (max-width: 768px) {
    .OtpInput {
        gap: 10px !important;
    }
}

.GwFlow-steps {
    /*padding: 20px 20px 10px;*/
    width: 100%;
    margin: auto;
    /*flex-wrap: wrap;*/
}
.GwFlow-steps .ant-steps-item {
    /*width: 10px;*/
}
.GwFlow-step_button {
    padding: 0px 5px;
    line-height: 0px;
    height: 4px;
    border-radius: 2px !important;
    background: #e0e0e0;
    pointer-events: none;
}
.GwFlow-step_button.GwFlow-step_button--current {
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item {
    /*padding-left: 0px*/
}
.GwFlow-steps-space > .ant-space-item:first-child {
    /*flex-grow: 1;*/
}
.GwFlow-step--clickable {
    cursor: pointer;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #777777 !important;
}

.DocumentTypeBoxSelect {
    text-align: left;
    margin: auto;
    width: 100%;
}
.DocumentTypeBoxSelect_radio-item {
    width: 100%;
    margin-top: 10px;
    border-radius: 10px !important;
    border-width: 2px;
    display: inline-table;
    position: relative;
    line-height: 24px !important;
}
.DocumentTypeBoxSelect_radio-item:first-child {
    border-left-width: 2px;
}
.DocumentTypeBoxSelect_radio-item:last-child {
    border-right-width: 2px;
}
.DocumentTypeBoxSelect_radio-item.DocumentTypeBoxSelect_radio-item--selected,
.DocumentTypeBoxSelect_radio-item:hover:not(
        .ant-radio-button-wrapper-disabled
    ) {
    color: #000000 !important;
}
.DocumentTypeBoxSelect_radio-item.DocumentTypeBoxSelect_radio-item--disabled
    .DocumentTypeBoxSelect_radio-item-icon {
    opacity: 0.5;
}
.DocumentTypeBoxSelect_radio-item-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.DocumentTypeBoxSelect_radio-item-checkbox {
    position: absolute;
    right: 15px;
    top: 10px;
}
.DocumentTypeBoxSelect_radio-item-checkbox .ant-checkbox-inner {
    border-radius: 50%;
    height: 20px;
    width: 20px;
}
.DocumentTypeBoxSelect_radio-item-checkbox .ant-checkbox-inner::after {
    left: 26.5%;
}
.DocumentTypeBoxSelect_radio-item:before {
    width: 0 !important;
}
.DocumentTypeBoxSelect_radio-item-inner {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    padding: 14px 0;
}

@import '~antd/dist/antd.css';
@font-face {
    font-family: 'InterVariable';
    src: url('./fonts/Inter-VariableFont_slnt,wght.ttf') format('ttf');
    font-weight: 100 900; /* Rango de peso soportado */
    font-style: oblique 0deg 10deg; /* Rango de inclinación soportado */
}
body {
    font-family:
        'InterVariable',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
}
.App {
    text-align: center;
}
.App.ant-layout {
    background: white;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-content {
    min-height: 100vh;
    /*padding: 24px 50px;*/
    background-position:
        right bottom,
        center;
    background-size: cover;
    background-attachment: fixed;
}

.App-content .site-layout-content {
    /*min-height: calc(100vh - 60px);*/
    display: flex;
    flex-direction: column;
}

.App-general-error {
    max-width: 560px;
    width: 50%;
    margin: auto;
}

@media (max-width: 768px) {
    .App-header {
        padding: 0;
    }
    .App-content {
        padding: 0;
    }
    .App-footer {
        padding: 0;
    }
}

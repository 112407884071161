.Button {
    min-width: 200px;
    width: 100%;
    /*max-width: 280px;*/
    border-radius: 8px;
    padding: 12px 12px !important;

    height: 50px;
    font-weight: 500;
}

.Button.ant-btn-primary {
    /*background-color: #000;*/
}

@media (max-width: 768px) {
    .Button {
        min-width: 100%;
        /*max-width: 100%;*/
    }
}

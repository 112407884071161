.ContentBox {
    /*height: calc(100vh - 60px);*/
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    min-width: 250px;
    width: 80%;
    flex: 1;
    /*height: 100%;*/
    overflow: auto;
    min-height: 500px;
    max-width: 600px;
}
.ContentBox.ContentBox--mobile {
    margin: 0;
    width: 100%;
    min-width: 100%;
    min-height: 100vh;
    max-width: inherit;
    /*position: fixed;*/
    /*left: 0;*/
    /*top: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
}
/*.ContentBox.ContentBox--mobile .ContentBox_body {*/
/*    overflow: scroll;*/
/*}*/

.ContentBox_header {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.ContentBox_footer {
    width: 100%;
    /*height: 50px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.ContentBox_body {
    width: 100%;
    flex: 1;
    height: 100%;
    padding: 10px;
}

.ContentBox_logo {
    margin: auto;
    text-align: center;
    margin-top: 50px;
    padding-bottom: 30px;
}

.ContentBox_form {
    width: 500px;
    background-color: #ffffff;
}

/*@media (max-width: 776px) {*/
/*    .ContentBox {*/
/*        margin: 0;*/
/*        width: 100%;*/
/*        min-width: 100%;*/
/*        min-height: 100vh;*/
/*        position: fixed;*/
/*        left: 0;*/
/*        top: 0;*/
/*        right: 0;*/
/*        bottom: 0;*/
/*    }*/
/*}*/

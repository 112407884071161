.Summary_item {
    justify-content: space-between !important;
    align-content: flex-start;
    /*margin-bottom: 10px;*/
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
}
.Summary_item-icon {
    font-size: 24px;
}
